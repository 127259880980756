<template>
  <div>
    <form v-on:submit.prevent="submitScore">
      <div class="row">
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Fecha de certificacion</label
          >
          <input
            type="date"
            v-model="form.fecha_certificacion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-8">
          <label class="col-form-label-sm">Certificado</label>
          <b-form-file
            v-model="form.certificado"
            placeholder="Elige un archivo o sueltalo aqui..."
            drop-placeholder="Suelta el archivo aqui..."
          ></b-form-file>
          <a v-show="certificadoUrl" :href="certificadoUrl"
            >Certificado actual</a
          >
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Demanda de calefacción [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.demanda_calefaccion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Demanda de refrigeración [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.demanda_refrigeracion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Emisiones globales [kgCO2/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.emisiones_global"
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Letra de calificacion [A:F]</label
          >
          <input
            type="text"
            v-model="form.letra_calificacion"
            class="form-control"
            required
            maxlength="1"
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Emisiones de Calefacción [kgCO2/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.emisiones_calefaccion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Emisiones de Refrigeración [kgCO2/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.emisiones_refrigeracion"
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Emisiones ACS [kgCO2/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.emisiones_acs"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Emisiones Iluminación [kgCO2/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.emisiones_iluminacion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Indicador global de consumo de energía no renovable
            [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.indicador_energia_no_renovable"
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Letra consumo [A:F]</label
          >
          <input
            type="text"
            v-model="form.letra_consumo"
            class="form-control"
            required
            maxlength="1"
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Consumo de calefaccion [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.consumo_calefaccion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Consumo de ACS [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.consumo_acs"
            class="form-control"
            required
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Consumo de refrigeración [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.consumo_refrigeracion"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-4">
          <label class="form-label col-form-label-sm"
            >Consumo de iluminación [kWh/m2año]</label
          >
          <input
            type="number"
            step="0.01"
            v-model="form.consumo_iluminacion"
            class="form-control"
            required
          />
        </div>
      </div>
      <small class="text-danger" v-if="this.submitError">
        Ha habido un error. Por favor, revise los datos.
      </small>
      <br /><br />
      <div class="row">
        <div class="col-sm-12">
          <button type="submit" class="btn btn-primary">
            Guardar y continuar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  data: () => ({
    buildingId: null,
    submitError: false,
    form: {
      fecha_certificacion: undefined,
      certificado: undefined,
      demanda_calefaccion: undefined,
      demanda_refrigeracion: undefined,
      emisiones_global: undefined,
      letra_calificacion: undefined,
      emisiones_calefaccion: undefined,
      emisiones_acs: undefined,
      emisiones_refrigeracion: undefined,
      emisiones_iluminacion: undefined,
      indicador_energia_no_renovable: undefined,
      letra_consumo: undefined,
      consumo_calefaccion: undefined,
      consumo_acs: undefined,
      consumo_refrigeracion: undefined,
      consumo_iluminacion: undefined,
      building: undefined,
    },
    certificadoUrl: null,
  }),

  async mounted() {
    this.buildingId = this.$route.params.id;
    this.form.building = this.buildingId;
    await this.getScore();
  },

  methods: {
    // async handleFile(e) {
    //   const selectedCertificado = e.target.files[0];
    //   this.uploadedFile = selectedCertificado;
    //   this.form.certificado = selectedCertificado.name;
    // },

    parseForm() {
      const formData = new FormData();
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) formData.append(key, this.form[key]);
      });

      if (typeof formData.get("certificado") === "string") {
        formData.delete("certificado");
      }

      return formData;
    },

    async getScore() {
      const { data } = await api.get(
        `energy_scores/?building=${this.buildingId}`
      );

      if (data.length !== 0) {
        this.form = data[0];
        if (this.form.certificado) {
          this.certificadoUrl = this.form.certificado;
          this.form.certificado = null;
        }
      }
    },

    async submitScore() {
      try {
        const parsedForm = this.parseForm();
        if (this.form.id) {
          await api.put(`energy_scores/${this.form.id}/`, parsedForm, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        } else {
          await api.post(`energy_scores/`, parsedForm, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
        this.$router.push({
          name: "improvements",
          params: { id: this.buildingId },
        });
      } catch {
        this.submitError = true;
      }
    },
  },
};
</script>

<style></style>
